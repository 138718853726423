<script setup lang="ts">
const { theme } = useHeaderStyles()
const { isAuthenticated } = useAuth()
const { i18nEnabled } = useTenantConfig()
const useI18n = computed(() => useAppConfig().app.useI18n && i18nEnabled)

const cmsMenuPosition = computed(() => theme.value.cmsMenu.position)

// Not sure if we always want to center the items
// For mas4you at least, it looks better
const centerItems = true

const isSticky = computed(() => theme.value.sticky)
const backdropBlur = computed(() => isSticky.value)
const bgOpacity = ref(0.90) // Adjustable opacity value (0-1)

const headerBgStyle = ref('')

// This is extremely hacky, but i wasn't able to make it work.
// this works: bg-[var(--color-theme-background)]
// but this not: bg-[var(--color-theme-background) / 0.95]
// that's why I created this.
// Note: this only works on the client, that's why we call it from onMounted
function updateHeaderBgStyle() {
  try {
    const bgColor = getComputedStyle(document.documentElement)
      .getPropertyValue(colorMode.value == 'dark' ? '--color-theme-background-dark' : '--color-theme-background')
      .trim()

    // Parse hex code
    if (bgColor.includes('#')) {
      // Convert hex to rgba
      let hex = bgColor.replace('#', '')

      // Handle shorthand hex
      if (hex.length === 3) {
        hex = hex.split('').map(c => c + c).join('')
      }

      // Convert to RGB
      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)

      headerBgStyle.value = `background-color: rgba(${r}, ${g}, ${b}, ${bgOpacity.value})`
    }
    // Parse rgb code
    else if (bgColor.includes('rgb')) {
      const r = bgColor.split(' ')[0].replace('rgb(', '').replace(')', '')
      const g = bgColor.split(' ')[1].replace(')', '')
      const b = bgColor.split(' ')[2].replace(')', '')
      headerBgStyle.value = `background-color: rgba(${r}, ${g}, ${b}, ${bgOpacity.value})`
    }
  }
  catch (error) {
    console.error('Error computing background style:', error)
  }
}

// Note: colorMode has issues with reactivity, so we need to watch it
const colorMode = useColorMode()
watch(colorMode, () => updateHeaderBgStyle())
onMounted(() => {
  updateHeaderBgStyle()
})

// TODO: use a theme var to toggle full width vs container width.
// Never use smaller max width for header
// max-w-7xl was the default for the header.
// We should add a theme var for this.
const { tenant } = useTenant()
provide('maxWidthClass', tenant.value != 'roermond' ? 'max-w-7xl' : '')
// @TODO: theme var
// for full width headers, we want to have side padding, otherwise not.
const hasSidePadding = tenant.value == 'roermond'

</script>

<template>
  <header
    :style="headerBgStyle"
    :class="{
      'px-4 md:px-6': hasSidePadding,
      'backdrop-blur-sm': backdropBlur,
      'fixed inset-x-0 top-0 z-50 w-full border-b border-[var(--color-theme-header-border)] ': isSticky,
      'bg-theme-background': !backdropBlur,
      'border-b border-[var(--color-theme-header-border)]': true,
    }"
  >
    <Container class="py-3" :apply-padding="false">
      
      <div class="hidden items-center justify-between gap-4 md:flex">
        
        <div
          class="flex h-16 min-w-32 gap-x-4"
          :class="{
            'mb-4': !isAuthenticated && cmsMenuPosition === 'top',
            'items-center': centerItems,
            'items-start': !centerItems,
          }"
        >
          <NuxtLink to="/">
            <PublicLogo class="max-h-12" />
          </NuxtLink>
        </div>

        
        <!-- <div class="scrollbar-thin overflow-x-auto">
          <PublicCmsMenu v-if="cmsMenuPosition === 'top'" />
        </div> -->

        
        <div class="flex items-start justify-end gap-3">
          <PublicCmsMenu v-if="cmsMenuPosition === 'top'" />
          <ToolbarLanguage v-if="i18nEnabled" />
          <BaseThemeToggle />
          <ToolbarAccountMenu v-if="isAuthenticated" />
          <ToolbarSignIn v-else />
        </div>
      </div>

      
      <div class="flex items-center justify-between gap-2 md:hidden">
        
        <TairoSidebarBurger />

        
        <NuxtLink to="/" class="flex items-end justify-end">
          <PublicLogo class="max-h-14 max-w-[80%]" />
        </NuxtLink>
        

        
        
        
      </div>
    </Container>

    <PublicCmsMenu v-if="cmsMenuPosition === 'bottom'" />

    <PublicAuthenticatedMenu v-if="isAuthenticated" />
  </header>

  
  
  <div
    v-if="isSticky"
    :class="{
      'h-16': true,
      'md:h-24': !isAuthenticated,
      'md:h-[141px]': isAuthenticated, // AARGH. Fix this.
    }"
  />
</template>
